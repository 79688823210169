import React, { Component } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { getLoans, getInterestIncome } from "../services/loanService";
import { getTakenLoans } from "../services/takenLoanService";
import {
  getNotReceivedFactorings,
  getCustomerSum,
  getCustomerSumByMonthly,
  getPayerSum,
  getWeeklyDueDate,
  getFactoringFees,
} from "../services/factoringService";
import { getBankBalance } from "../services/bankService";
import { BarChart, XAxis, YAxis, Tooltip, CartesianGrid, Bar, Legend } from "recharts";
import TableNoSort from './common/tableNoSort';
import { monthlyColumnsAndTotal } from '../utils/factoringMonthFeesColumns';
import {
  getProfit,
  getReport,
} from '../services/accountingService';

const STARTING_DATE = new Date(2024, 12);

class ComapanyDashboard extends Component {
  state = {
    totalLoansCount: 0,
    activeLoansBalance: 0,
    openFactoringBalance: 0,
    overdueLoansBalance: 0,
    totalLoansCompletedCount: 0,
    chartData: [],
    factoringsByCustomer: [],
    factoringsByPayer: [],
    totalBalancesChartData: [],
    weeklyDueDate: [],
    interestAndFees: [],
    customerSumByMonthly: [],
    profit: [],
    incomeStatement: [],
  }

  async componentDidMount() {
    const { data: loans } = await getLoans();
    const { data: takenLoans } = await getTakenLoans();
    const { data: factorings } = await getNotReceivedFactorings();
    const { data: factoringsByCustomer } = await getCustomerSum();
    const { data: factoringsByPayer } = await getPayerSum();
    const { data: weeklyDueDate } = await getWeeklyDueDate();
    const { data: bankBalanceObj } = await getBankBalance();
    const { data: monthlyFees } = await getFactoringFees();
    const { data: interestIncome } = await getInterestIncome();
    const { data: customerSumByMonthly } = await getCustomerSumByMonthly();
    const { data: profitData } = await getProfit();
    const { data: incomeStatement } = await getReport();

    const bankBalance = bankBalanceObj.balance;
    const activeLoans = loans.filter(item => item.loanStatusName === 'Active');
    const overdueLoans = loans.filter(item => item.loanStatusName === 'Debtor');
    const openFactorings = factorings.filter(item => item.paymentDate !== null);
    const activeTakenLoans = takenLoans.filter(item => item.loanStatusName === 'Active');

    const interestAndFees = monthlyFees.map(element => {
      const interestObj = interestIncome.find(item => item.month === element.month);

      return {
        month: element.month,
        fee: element.amount,
        interest: (interestObj && interestObj.amount) || 0,
        total: Math.round((element.amount + ((interestObj && interestObj.amount) || 0)) * 100) / 100,
      }
    });

    const profit = profitData.map(element => {
      return {
        monthAndYear: Number(element.year.toString() + (element.month + 1).toString()),
        income: Math.round(element.income * 100) / 100,
        cost: Math.round(element.cost * 100) / 100,
        profit: Math.round(element.profit * 100) / 100,
      }
    });

    profit.sort((a, b) => (a.monthAndYear > b.monthAndYear) ? 1 : -1);

    const activeLoansBalance = activeLoans.reduce(function (a, b) {
      return a + b.balance
    }, 0);
    const overdueLoansBalance = overdueLoans.reduce(function (a, b) {
      return a + b.balance
    }, 0);
    const activeTakenLoansBalance = activeTakenLoans.reduce(function (a, b) {
      return a + b.balance
    }, 0);
    const openFactoringBalance = openFactorings.reduce(function (a, b) {
      return a + b.invoiceAmount
    }, 0);
    const expectedFee = openFactorings.reduce(function (a, b) {
      return a + b.fee
    }, 0);
    const activeAndOverdueLoans = activeLoans.concat(overdueLoans);
    const chartData = activeAndOverdueLoans.map(item => {
      const data = {
        name: item.customerName,
        amount: item.amount,
        balance: item.balance
      }
      return data;
    })

    const totalBalancesChartData =
      [
        {
          name: 'Loans',
          balance: Math.round((activeLoansBalance) * 100) / 100,
        },
        {
          name: 'Taken Loans',
          balance: Math.round(activeTakenLoansBalance * 100) / 100,
        },
        {
          name: 'Factorings',
          balance: Math.round(openFactoringBalance * 100) / 100,
        },
        {
          name: 'Factorings + Loans',
          balance: Math.round(openFactoringBalance * 100) / 100 + Math.round((activeLoansBalance) * 100) / 100,
        },
        {
          name: 'Cash',
          balance: bankBalance,
        },
        {
          name: 'Equity',
          balance: Math.round(((bankBalance + openFactoringBalance + activeLoansBalance) - activeTakenLoansBalance) * 100) / 100,
        },
        {
          name: 'Assets',
          balance: Math.round(((bankBalance + openFactoringBalance + activeLoansBalance)) * 100) / 100,
        },
        {
          name: 'Expected Fees',
          balance: Math.round(expectedFee * 100) / 100,
        },
        {
          name: 'Debt-to-Equity Ratio',
          balance: Math.round(activeTakenLoansBalance / ((bankBalance + openFactoringBalance + activeLoansBalance) - activeTakenLoansBalance) * 100) / 100,
        },
      ]

    this.setState({
      factoringsByCustomer,
      factoringsByPayer,
      activeLoansBalance,
      overdueLoansBalance,
      activeTakenLoansBalance,
      openFactoringBalance,
      chartData,
      totalBalancesChartData,
      weeklyDueDate,
      interestAndFees,
      customerSumByMonthly,
      profit,
      incomeStatement,
    });
  }

  currencyFormating = amount => {
    return !amount ? 0 : amount.toLocaleString(undefined, { style: 'currency', currency: 'EUR' }, { maximumFractionDigits: 2 });
  }

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getCurrentMonths = () => {
    const diffInMonths = (startDate, endDate) => {
      const diffInYears = endDate.getFullYear() - startDate.getFullYear();
      return diffInYears * 12 - startDate.getMonth() + endDate.getMonth();
    }

    const requireMonths = diffInMonths(STARTING_DATE, new Date()) + 1;

    const updatedMonths = [];

    // Add name from array
    updatedMonths.push(monthlyColumnsAndTotal[0]);

    // Add needed months
    for (let i = 1; i < requireMonths + 1; i++) {
      updatedMonths.push(monthlyColumnsAndTotal[i]);
    }

    // Add name grand total array
    updatedMonths.push(monthlyColumnsAndTotal.slice(-1)[0]);

    return updatedMonths;

  }

  render() {
    const {
      openFactoringBalance,
      activeLoansBalance,
      overdueLoansBalance,
      chartData,
      totalBalancesChartData,
      activeTakenLoansBalance,
      factoringsByCustomer,
      factoringsByPayer,
      weeklyDueDate,
      interestAndFees,
      customerSumByMonthly,
      profit,
      incomeStatement,
    } = this.state;

    return (
      < div className="page">
        <div className="row" style={{ marginBottom: 30 }}>
          <div className="col">
            <h1 className="font-weight-bold text-secondary">DASHBOARD</h1>
          </div>
        </div>
        <div className="row" style={{ marginBottom: 10, width: 1200 }}>
          <div className="col">
            <h3>Balances:</h3>
            <div className="row" style={{ marginBottom: 50 }}>
              <BarChart
                width={400}
                height={600}
                data={totalBalancesChartData}
                margin={{ top: 5, right: 20, left: 10, bottom: 150 }}
              >
                <XAxis dataKey="name" angle={-70} dy={70} />
                <YAxis orientation="left" />
                <Legend verticalAlign="top" />
                <Tooltip />
                <CartesianGrid stroke="lightblue" />
                <Bar type="monotone" dataKey="balance" fill="skyblue" maxBarSize={55} isAnimationActive={false}>
                </Bar>
              </BarChart>
            </div>
          </div>
          <div className="col">
            <h2>Portfel statistics:</h2>
            <h4 className="text-muted">
              <Link to="/factoring/notreceived">
                <p style={{ fontWeight: 'normal' }}>Factorings: {this.currencyFormating(openFactoringBalance)}</p>
              </Link>
            </h4>
            <h4 className="text-muted">
              Active loans: {this.currencyFormating(activeLoansBalance)}
            </h4>
            <h4 className="text-muted">
              Overdue loans: {this.currencyFormating(overdueLoansBalance)}
            </h4>
            <h4 className="text-muted">
              Taken Loans {this.currencyFormating(activeTakenLoansBalance)}
            </h4>
            <h4 className="text-muted">
              Equity {this.currencyFormating(totalBalancesChartData.length > 1 ? totalBalancesChartData[5].balance : 0)}
            </h4>
            <h4 className="text-muted">
              Cash {this.currencyFormating(totalBalancesChartData.length > 1 ? totalBalancesChartData[4].balance : 0)}
            </h4>
            <h4 className="text-muted">
              Assets {this.currencyFormating(totalBalancesChartData.length > 1 ? totalBalancesChartData[6].balance : 0)}
            </h4>
            <h4 className="text-muted">
              Expected fees {this.currencyFormating(totalBalancesChartData.length > 1 ? totalBalancesChartData[7].balance : 0)}
            </h4>
            <h4 className="text-muted">
              Debt-to-Equity Ratio {this.currencyFormating(totalBalancesChartData.length > 1 ? totalBalancesChartData[8].balance : 0)}
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h3>Loans:</h3>
            <div className="row" style={{ marginBottom: 50 }}>
              <BarChart
                width={800}
                height={600}
                data={chartData}
                margin={{ top: 5, right: 20, left: 10, bottom: 150 }}
              >
                <XAxis dataKey="name" angle={-70} dy={70} />
                <YAxis orientation="left" />
                <Legend verticalAlign="top" />
                <Tooltip />
                <CartesianGrid stroke="lightblue" />
                <Bar type="monotone" dataKey="balance" fill="skyblue" maxBarSize={55} isAnimationActive={false}>
                </Bar>
                <Bar type="monotone" dataKey="amount" fill="lightseagreen" maxBarSize={55} isAnimationActive={false} >
                </Bar>
              </BarChart>
            </div>
          </div>
          <div className="col">
            <h3>Weekly factoring cashflow:</h3>
            <div className="row" style={{ marginBottom: 50 }}>
              <BarChart
                width={800}
                height={600}
                data={weeklyDueDate}
                margin={{ top: 5, right: 20, left: 10, bottom: 150 }}
              >
                <XAxis dataKey="week" />
                <YAxis orientation="left" />
                <Legend verticalAlign="top" />
                <Tooltip />
                <CartesianGrid stroke="lightblue" />
                <Bar type="monotone" dataKey="dueDate" fill="skyblue" maxBarSize={55} isAnimationActive={false}>
                </Bar>
                <Bar type="monotone" dataKey="received" fill="lightseagreen" maxBarSize={55} isAnimationActive={false} >
                </Bar>
              </BarChart>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h3>Factoring by customer:</h3>
            <div className="row" style={{ marginBottom: 50 }}>
              <BarChart
                width={800}
                height={800}
                data={factoringsByCustomer}
                margin={{ top: 5, right: 20, left: 50, bottom: 50 }}
                layout="vertical"
              >
                <XAxis type="number" dataKey="balance" />
                <YAxis dataKey="name" type='category' fontSize='10' width={50} />
                <Legend verticalAlign="top" />
                <Tooltip />
                <CartesianGrid stroke="lightblue" />
                <Bar type="monotone" dataKey="limit" fill="skyblue" maxBarSize={55} isAnimationActive={false}>
                </Bar>
                <Bar type="monotone" dataKey="balance" fill="lightseagreen" maxBarSize={55} isAnimationActive={false}>
                </Bar>
              </BarChart>
            </div>
          </div>
          <div className="col">
            <h3>Factoring by payer:</h3>
            <div className="row" style={{ marginBottom: 50 }}>
              <BarChart
                width={700}
                height={800}
                data={factoringsByPayer}
                margin={{ top: 5, right: 20, left: 50, bottom: 50 }}
                layout="vertical"
              >
                <XAxis type='number' dataKey="balance" />
                <YAxis dataKey="name" type='category' fontSize='10' width={50} />
                <Legend verticalAlign="top" />
                <Tooltip />
                <CartesianGrid stroke="lightblue" />
                <Bar type="monotone" dataKey="balance" fill="skyblue" maxBarSize={55} isAnimationActive={false}>
                </Bar>
              </BarChart>
            </div>

          </div>
        </div>
        <div className="row">
          <div className="col">
            <h3>Monthly interest and fee income:</h3>
            <h5>Income by payments</h5>
            <div className="row" style={{ marginBottom: 50 }}>
              <BarChart
                width={800}
                height={600}
                data={interestAndFees}
                margin={{ top: 5, right: 20, left: 10, bottom: 150 }}
              >
                <XAxis dataKey="month" />
                <YAxis orientation="left" />
                <Legend verticalAlign="top" />
                <Tooltip />
                <CartesianGrid stroke="lightblue" />
                <Bar type="monotone" dataKey="total" fill="pink" maxBarSize={55} isAnimationActive={false}>
                </Bar>
                <Bar type="monotone" dataKey="fee" fill="skyblue" maxBarSize={55} isAnimationActive={false}>
                </Bar>
                <Bar type="monotone" dataKey="interest" fill="lightseagreen" maxBarSize={55} isAnimationActive={false}>
                </Bar>
              </BarChart>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h3>Income Statement:</h3>
            <h5>IS by payments</h5>
            <div className="row" style={{ marginBottom: 50 }}>
              <BarChart
                width={1000}
                height={600}
                data={profit}
                margin={{ top: 5, right: 20, left: 10, bottom: 150 }}
              >
                <XAxis dataKey="monthAndYear" />
                <YAxis orientation="left" />
                <Legend verticalAlign="top" />
                <Tooltip />
                <CartesianGrid stroke="lightblue" />
                <Bar type="monotone" dataKey="profit" fill="pink" maxBarSize={55} isAnimationActive={false}>
                </Bar>
                <Bar type="monotone" dataKey="income" fill="skyblue" maxBarSize={55} isAnimationActive={false}>
                </Bar>
                <Bar type="monotone" dataKey="cost" fill="lightseagreen" maxBarSize={55} isAnimationActive={false}>
                </Bar>
              </BarChart>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h3>2025 Income Statement</h3>
            <h5>Updated hourly</h5>
            <div className="row" style={{ margin: 20 }}>
              <TableNoSort columns={this.getCurrentMonths()} data={incomeStatement} ></TableNoSort>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h3>2025 monthly fees by customer:</h3>
            <h5>Fees are taken in account if payment is received</h5>
            <div className="row" style={{ margin: 20 }}>
              <TableNoSort columns={this.getCurrentMonths()} data={customerSumByMonthly} ></TableNoSort>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default ComapanyDashboard;
